.border-animation {
  background: linear-gradient(90deg, #4C4C4C 50%, transparent 0) repeat-x, linear-gradient(90deg, #4C4C4C 50%, transparent 0) repeat-x, linear-gradient(0deg, #4C4C4C 50%, transparent 0) repeat-y, linear-gradient(0deg, #4C4C4C 50%, transparent 0) repeat-y;
  background-size: 12px 0px, 12px 1px, 1px 12px, 1px 12px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  cursor: pointer;
  animation: linearGradientMove .5s infinite linear;
}

@keyframes linearGradientMove {
  0% {
    background-position: 12px 0, -12px 100%, 0 -12px, 100% 12px;
  }
}