.reload.active img {

  animation: rotate 0.5s linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(-360deg)
  }
}